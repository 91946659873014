import IReport from "../types/IReport";
import ITestResultWithUser from "../types/ITestResultWithUser";
import IUserWithResults from "../types/IUserWithResults";
import { getStatsFromResults } from "./statsUtils";

export const groupCompanyTestResults = <T extends IReport | ITestResultWithUser>(
  results: T[],
  searchString?: string
): IUserWithResults<T>[] => {
  const userResults = Object.values(
    results.reduce((acc: { [key: string]: T[] }, result: T) => {
      if (!searchString || result.user.name.toLowerCase().includes(searchString.toLowerCase())) {
        acc[result.user.id] = acc[result.user.id] || [];
        acc[result.user.id].push(result);
      }
      return acc;
    }, {})
  );

  return userResults
    .map((values) => {
      return {
        ...values[0].user,
        ...getStatsFromResults(values),
        results: values.sort((a, b) => +b.result - +a.result)
      };
    })
    .sort((a, b) => +b.highestResult.result - +a.highestResult.result);
};

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { IPublicFile } from "../../../types/IPublicFile";

interface FileUploadProps {
  canUpload: boolean;
  files: IPublicFile[]; // Lista plików z backendu
  onUpload: (file: File) => void; // Funkcja wywoływana przy wyborze nowego pliku
  onRemoveFile: (fileId: number) => void; // Funkcja usuwania pliku
}

// Pomocnicza funkcja – wycina wszystko do pierwszego myślnika
function getSubstringAfterFirstDash(str: string) {
  const regex =
    /^(?:.*?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-)(.*)$/;
  const match = str.match(regex);
  return match && match[1] ? match[1] : str;
}

const FileUpload = ({ canUpload, files = [], onUpload, onRemoveFile }: FileUploadProps) => {
  // Obsługa zdarzenia zmiany pliku w input

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onUpload(event.target.files[0]);
    }
  };

  return (
    <Box sx={{ p: 2, border: "1px dashed #ccc", borderRadius: 1 }}>
      {canUpload && (
        <>
          <Typography variant="h6" gutterBottom>
            Upload pliku (PDF, Word, Excel, inne dokumenty, obrazy)
          </Typography>
          <Button variant="contained" component="label">
            Dodaj plik
            <input
              type="file"
              accept=".pdf, .doc, .docx, .xls, .xlsx, image/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
        </>
      )}

      {/* Lista plików z backendu */}
      {files?.map((file) => {
        const fileUrl = file.url || "";
        const fileName = getSubstringAfterFirstDash(file.key || "") || "unknown";
        const fileType = file.type || "";
        const isImage = fileType.startsWith("image/");

        return (
          <Box key={file.id} sx={{ mt: 2 }}>
            {/* Nazwa pliku jako link pobierający (download) + przycisk "Usuń" */}
            <Box>
              <Typography
                variant="body1"
                component="a"
                href={fileUrl}
                download={fileName}
                sx={{
                  textDecoration: "none",
                  color: "primary.main",
                  "&:hover": { textDecoration: "underline" },
                  cursor: "pointer",
                  mr: 2
                }}
              >
                {fileName}
              </Typography>

              {canUpload && (
                <Button variant="outlined" color="error" onClick={() => onRemoveFile(file.id)}>
                  Usuń
                </Button>
              )}
            </Box>

            {/* Podgląd obrazu, jeśli to typ image/* */}
            {isImage && (
              <Box
                component="img"
                src={fileUrl}
                alt="Podgląd pliku"
                sx={{ mt: 1, maxWidth: "100%", height: "auto" }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default FileUpload;

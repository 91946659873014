import { Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useParams, useNavigate, Link } from "react-router-dom";
import PaperStepper from "../../../components/PaperStepper";
import { getCompanyCardDetails } from "../../../services/company.service";
import { useTranslation } from "react-i18next";
import { GroupFilter } from "../../common/company/groupFilter";
import CompanyTestResultsStats from "../test/CompanyTestResultsStats";
import { CompanyTestResultsList } from "../test/CompanyTestResultsList";
import HeaderWithIcon from "../../../components/HeaderWithIcon";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ICardWithResults from "../../../types/ICardWithResults";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { themePalette } from "../../../themes/palette";
import { getGroupsFromList } from "../../../utils/filterUtils";
import IGroup from "../../../types/IGroup";
import useAlerts from "../../../hooks/useAlerts";
import { getStatsFromResults } from "../../../utils/statsUtils";
import IUserWithResults from "../../../types/IUserWithResults";
import IReport from "../../../types/IReport";
import { R_COMPANY_RIDE } from "../../../config/routes";
import { groupCompanyTestResults } from "../../../utils/groupCompanyTestResults";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { ICardWithGroupWithItems } from "../../../types/ICard";

const CompanyRidesCard = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchUser, setSearchUser] = useState<string>();
  const [results, setResults] = useState<ICardWithResults>();
  const [groupId, setGroupId] = useState<IGroup["id"]>(-1);

  const groupFilterHandler = (id: IGroup["id"]) => {
    setGroupId(id);
  };

  useEffect(() => {
    if (id) {
      getCompanyCardDetails(id)
        .then(({ data }) => {
          setResults(data);
        })
        .catch(() => {
          error();
        });
    }
  }, [id]);

  const getFilteredResults = () => {
    if (results) {
      if (groupId !== -1) {
        return results.results.filter((item) => item.user.groups.some((el) => el.id === groupId));
      } else {
        return results.results;
      }
    }
    return [];
  };

  const filteredResults = getFilteredResults();

  const card: ICardWithResults | undefined =
    results && groupId && groupId > 0
      ? {
          ...results,
          results: filteredResults,
          ...getStatsFromResults(filteredResults)
        }
      : results;

  const groupedFilteredResults: IUserWithResults<IReport>[] = groupCompanyTestResults(
    filteredResults,
    searchUser
  );


  console.log(results);
  const groups: Record<string, number[]> = {};

  results?.results.forEach((result) => {
    console.log(result);
    const getGroupAndMaxPointsByItemId = (id: string) => {
      const data = Object.entries(result.template.points).find(([groupId, itemsWithPoints]) => {
        return !!itemsWithPoints[+id];
      });

      if (!data) return;
      return {
        groupId: data[0],
        maxPoints: data[1][+id]
      };
    };

    Object.entries(result.answers).forEach(([itemId, itemValue]) => {
      const x = getGroupAndMaxPointsByItemId(itemId);
      if (!x) return;

      const { groupId, maxPoints } = x;
      if (!groups[groupId]) groups[groupId] = [];
      groups[groupId].push(Math.round((itemValue / maxPoints) * 100));
    });
  });

  console.log(groups, Object.values(groups));

  const graphGroupData = [];

  for (const groupsKey in groups) {
    console.log(groupsKey);
    graphGroupData.push(
      Math.round(
        groups[groupsKey].reduce((acc, value) => acc + value, 0) / groups[groupsKey].length
      )
    );
  }

  const graphOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%"
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      x: {
        formatter: (cat: any) => {
          return cat?.name?.pl || "";
        }
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: (results as ICardWithGroupWithItems | undefined)?.groups || [],
      labels: {
        formatter: (group: any, index, elo) => {
          const str = group?.name?.pl || "";
          return str.length > 10 ? str.slice(0, 10) + "..." : str;
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100
    },
    fill: {
      opacity: 1,
      colors: ["#f15a22"]
    }
  };

  const graphSeries = [
    {
      name: "Średnie wyniki",
      data: graphGroupData
    }
  ];

  return (
    <Box>
      <PaperStepper steps={[t("Wybierz kartę"), t("Statystyki karty")]} step={1} />
      <Box mt={2}>
        <Link
          to=""
          className="back_button"
          onClick={() => navigate(-1)}
          style={{ textDecoration: "none", color: themePalette.primary.main }}
        >
          <ArrowBackIcon sx={{ verticalAlign: "top" }} /> <span>{t("Wstecz")}</span>
        </Link>
      </Box>

      <Box mt={2} mb={2}>
        <ArrayLoadingSkeleton data={[card]}>
          <Paper>
            <Box padding={2}>
              <Typography gutterBottom={false} variant="h5">
                {t("Statystyki karty ocen")}: {card?.name ?? ""}
              </Typography>
            </Box>
          </Paper>
        </ArrayLoadingSkeleton>
      </Box>

      <Paper sx={{ paddingTop: "1rem" }}>
        {results && (
          <GroupFilter list={getGroupsFromList(results.results)} onSelect={groupFilterHandler} />
        )}
      </Paper>

      <Paper sx={{ paddingTop: "2rem", marginTop: "1rem" }}>
        <CompanyTestResultsStats data={card} />
      </Paper>

      <Paper sx={{ margin: "1rem 0" }}>
        <Box padding={2}>
          <ReactApexChart options={graphOptions} series={graphSeries} type="bar" height={350} />
        </Box>
      </Paper>

      <Paper sx={{ margin: "1rem 0" }}>
        <HeaderWithIcon
          title={t("Karty")}
          count={groupedFilteredResults?.length}
          icon={DescriptionOutlinedIcon}
        />
      </Paper>

      <Paper sx={{ margin: "1rem 0" }}>
        <Box padding={2}>
          <TextField
            label="Wyszukaj uczestnika"
            variant="outlined"
            fullWidth
            value={searchUser}
            onChange={(data) => setSearchUser(data.target.value)}
          />{" "}
        </Box>
      </Paper>

      <Paper>
        <CompanyTestResultsList data={groupedFilteredResults} linkTo={R_COMPANY_RIDE} />
      </Paper>
    </Box>
  );
};

export default CompanyRidesCard;

import { Box, Paper, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { R_INDEX } from "../../config/routes";

import { getRide } from "../../services/card.service";
import useAlerts from "../../hooks/useAlerts";
import Chat from "../common/chat/Chat";
import RideResult from "../common/ride/RideResult";
import IReportWithCard from "../../types/IReportWithCard";
import FileUpload from "../common/fileUpload/FileUpload";
import { IPublicFile } from "../../types/IPublicFile";
import { removeFile, uploadFileReportChange } from "../../services/report.service";

const TrainerRideSummary = (): JSX.Element => {
  const { id = 0 } = useParams();
  const navigate = useNavigate();

  const { error, warning, success } = useAlerts();

  const [ride, setRide] = useState<IReportWithCard>();

  useEffect(() => {
    if (id) {
      getRide(id)
        .then((data) => {
          if (data.data.endedAt) {
            data.data.card.groups.sort((a, b) => (a.index > b.index ? 1 : -1));

            setRide(data.data);
            setFiles(data.data.files || []);
          } else {
            warning("Ewaluacja trwa");
            navigate(R_INDEX);
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            error();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [file, setFiles] = useState<IPublicFile[]>([]);

  function handleFileUpload(file: File) {
    if (ride) {
      uploadFileReportChange(ride.id, file)
        .then((data) => {
          setFiles(data.data.files);
          success();
        })
        .catch((err) => {
          error();
        });
    }
  }

  function handleFileRemove(fileId: number) {
    if (ride) {
      removeFile(ride.id, fileId)
        .then(({ data }) => {
          setFiles(data.files);
          success();
        })
        .catch(() => {
          error();
        });
    }
  }

  return (
    <Box py={2}>
      {!ride && (
        <Paper>
          <Skeleton animation="wave" sx={{ width: "100%" }} />
        </Paper>
      )}
      {ride && <RideResult result={ride} />}
      <Box py={2} p={1}>
        {ride && (
          <FileUpload
            canUpload={true}
            files={file}
            onRemoveFile={handleFileRemove}
            onUpload={handleFileUpload}
          />
        )}
      </Box>
      <Box py={2}>{ride && <Chat chatKey={`ride-${ride.id}`} />}</Box>
    </Box>
  );
};

export default TrainerRideSummary;

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import IUserWithResults from "../../../types/IUserWithResults";
import StatsResultItem from "../../../components/StatsResultItem";
import ITestResultWithUser from "../../../types/ITestResultWithUser";
import IReport from "../../../types/IReport";

export function CompanyTestResultsList({
  data,
  linkTo
}: Readonly<{
  data?: IUserWithResults<IReport | ITestResultWithUser>[];
  linkTo: (id?: string | number) => string;
}>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak wyników do wyświetlenia")} data={data}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Uczestnik")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <StatsResultItem key={item.id} data={item} linkTo={linkTo} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}

import {
  Box,
  Paper,
  Button,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  Skeleton,
  Backdrop,
  CircularProgress,
  Grid,
  Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { R_INDEX, R_RIDE_FINISH } from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import { finishRide, getRide, saveRide } from "../../services/card.service";
import { useTranslation } from "react-i18next";
import RideHeader from "../common/ride/components/RideHeader";
import RideGroupElement from "../common/ride/components/RideGroupElement";
import { ICardGroupWithItems } from "../../types/ICardGroup";
import ICardGroupItem from "../../types/ICardGroupItem";
import TrainerRideGroupItem from "./ride/TrainerRideGroupItem";
import IReport from "../../types/IReport";
import IReportWithCard from "../../types/IReportWithCard";
import { IPublicFile } from "../../types/IPublicFile";
import { removeFile, uploadFileReportChange } from "../../services/report.service";
import FileUpload from "../common/fileUpload/FileUpload";
import Chat from "../common/chat/Chat";

const Ride = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id = 0 } = useParams();

  const { error, info, success, warning } = useAlerts();

  const [modalFinish, setModalFinish] = useState<boolean>(false);

  const [ride, setRide] = useState<IReportWithCard>();

  const [rangeValues, setRangeValues] = useState<Record<string, number>>({});
  const [comments, setComments] = useState<Record<string, string>>({});

  const [loading, setLoading] = useState<boolean>(false);

  function endRide() {
    setLoading(true);

    finishRide(id, rangeValues, comments)
      .then((data) => {
        success(t("Zakończono ewaluację"));

        navigate(R_RIDE_FINISH(data.data.id));
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
          setLoading(false);
        }
      });
  }

  function handleSave() {
    setLoading(true);

    saveRide(id, rangeValues, comments)
      .then(() => {
        success(t("Zapisano wyniki"));
        navigate(R_INDEX);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
          setLoading(false);
        }
      });
  }

  function checkTheAnswers() {
    if (
      Object.keys(rangeValues).length ===
      ride?.card.groups.reduce((acc, group) => {
        return acc + group.items.length;
      }, 0)
    ) {
      setModalFinish(true);
    } else {
      warning(t("Nie przydzielono ocen do wszystkich zadań!"));
    }
  }

  function handleUpdateRanges(id: string, value: number) {
    setRangeValues((values) => ({ ...values, [id]: value }));
  }

  function handleUpdateComments(id: string, newComments: string) {
    setComments((comments) => ({ ...comments, [id]: newComments }));
  }

  useEffect(() => {
    if (id) {
      getRide(id)
        .then(({ data }) => {
          if (!data.endedAt) {
            data.card.groups.sort((a, b) => (a.index === b.index ? 0 : a.index > b.index ? 1 : -1));

            setRide(data);
            setComments(data.comments ?? {});
            setRangeValues(data.answers ?? {});
          } else {
            info(t("Zakończono ewaluację"));
            navigate(R_RIDE_FINISH(data.id));
          }
        })
        .catch((err) => {
          console.error(err);
          error();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [file, setFiles] = useState<IPublicFile[]>([]);

  function handleFileUpload(file: File) {
    if (ride) {
      uploadFileReportChange(ride.id, file)
        .then((data) => {
          setFiles(data.data.files);
          success();
        })
        .catch((err) => {
          error();
        });
    }
  }

  function handleFileRemove(fileId: number) {
    if (ride) {
      removeFile(ride.id, fileId)
        .then(({ data }) => {
          setFiles(data.files);
          success();
        })
        .catch(() => {
          error();
        });
    }
  }

  return (
    <>
      <Box>
        {!ride && (
          <Paper>
            <Skeleton animation="wave" sx={{ width: "100%" }} />
          </Paper>
        )}
        {ride && (
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <RideHeader report={ride} />
            </Grid>

            <Grid item xs={12}>
              <Box p={1}>
                <FileUpload
                  canUpload={true}
                  files={file}
                  onRemoveFile={handleFileRemove}
                  onUpload={handleFileUpload}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Chat chatKey={`ride-${ride.id}`} />
            </Grid>

            {ride.card.groups.map((group: ICardGroupWithItems, index: number) => (
              <Grid item xs={12} key={index}>
                <RideGroupElement group={group} index={index + 1}>
                  {group.items.map((item: ICardGroupItem, index: number) => (
                    <TrainerRideGroupItem
                      points={rangeValues[item.id]}
                      onPoint={(value) => handleUpdateRanges(item.id + "", value)}
                      comment={comments[item.id]}
                      onComment={(value) => handleUpdateComments(item.id + "", value)}
                      item={item}
                      key={index}
                    />
                  ))}
                </RideGroupElement>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" onClick={() => handleSave()} disabled={loading}>
                  Zapisz wyniki
                </Button>
                <Button variant="outlined" onClick={() => checkTheAnswers()} disabled={loading}>
                  Zakończ ewaluację
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>

      <Dialog open={modalFinish} onClose={() => setModalFinish(false)}>
        <DialogTitle>Czy na pewno chcesz zakończyć ewaluację ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Po zakończeniu ewaluacji nie będziesz w stanie zmienić oceny poszczególnych zadań oraz
            dodać komentarz
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalFinish(false)} variant="outlined">
            Wróć
          </Button>
          <Button autoFocus onClick={() => endRide()} variant="outlined">
            Zakończ
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Ride;

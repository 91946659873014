import axios, { AxiosPromise } from "axios";

//TODO: add result class
export const getReports = (): AxiosPromise<any> => axios.get(`/api/cards/rides`);

export const uploadFileReportChange = (reportId: number, file: File): AxiosPromise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`/api/cards/rides/${reportId}/file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export async function removeFile(rideId: number, fileId: number): Promise<any> {
  return await axios.delete(`/api/cards/rides/${rideId}/file/${fileId}`);
}

import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Stack, Grid, Paper, Typography, TextField } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { themePalette } from "../../themes/palette";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCompanyTestDetails } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import PaperStepper from "../../components/PaperStepper";
import { useTranslation } from "react-i18next";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import HeaderWithIcon from "../../components/HeaderWithIcon";
import ITestWithResults from "../../types/ITestWithResults";
import IUserWithResults from "../../types/IUserWithResults";
import { CompanyTestResultsList } from "./test/CompanyTestResultsList";
import CompanyTestResultsStats from "./test/CompanyTestResultsStats";
import { GroupFilter } from "../common/company/groupFilter";
import { getGroupsFromList } from "../../utils/filterUtils";
import { ApexOptions } from "apexcharts";
import ITestResultWithUser from "../../types/ITestResultWithUser";
import IGroup from "../../types/IGroup";
import { getStatsFromResults } from "../../utils/statsUtils";
import { R_TEST_VIEW } from "../../config/routes";
import { groupCompanyTestResults } from "../../utils/groupCompanyTestResults";

const CompanyTestStats = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchUser, setSearchUser] = useState<string>();
  const [results, setResults] = useState<ITestWithResults>();
  const [groupId, setGroupId] = useState<IGroup["id"]>(-1);

  const groupFilterHandler = (id: IGroup["id"]) => {
    setGroupId(id);
  };

  useEffect(() => {
    if (id) {
      getCompanyTestDetails(id)
        .then(({ data }) => {
          setResults(data);
        })
        .catch(() => {
          error();
        });
    }
  }, [id]);

  const getFilteredResults = () => {
    if (results) {
      if (groupId !== -1) {
        return results.results.filter((item) => item.user.groups.some((el) => el.id === groupId));
      } else {
        return results.results;
      }
    }
    return [];
  };

  const filteredResults = getFilteredResults();

  const test: ITestWithResults | undefined =
    results && groupId && groupId > 0
      ? {
          ...results,
          results: filteredResults,
          ...getStatsFromResults(filteredResults)
        }
      : results;

  const groupedFilteredResults: IUserWithResults<ITestResultWithUser>[] = groupCompanyTestResults(
    filteredResults,
    searchUser
  );

  const graphOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%"
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      x: {
        formatter: (cat: any) => {
          return cat?.name || "";
        }
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: groupedFilteredResults.map((result) => result),
      labels: {
        formatter: (cat: any, index, elo) => {
          const slashed = cat?.name?.trim().split(" ") || ["-"];
          return slashed.map((el: any) => el[0]);
        }
      }
    },
    yaxis: {
      max: 100,
      min: 0
    },
    fill: {
      opacity: 1,
      colors: ["#f15a22"]
    }
  };

  const graphSeries = [
    {
      name: "Najlepsze wyniki",
      data: groupedFilteredResults.map((result) => result.highestResult.result)
    }
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaperStepper steps={[t("Wybierz egzamin"), t("Statystyki egzaminu")]} step={1} />
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Link
            to=""
            className="back_button"
            onClick={() => navigate(-1)}
            style={{ textDecoration: "none", color: themePalette.primary.main }}
          >
            <ArrowBackIcon sx={{ verticalAlign: "top" }} /> <span>{t("Wstecz")}</span>
          </Link>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={[test]}>
          <Paper>
            <Box padding={2}>
              <Typography gutterBottom={false} variant="h5">
                {t("Statystyki egzaminu")}: {test?.name ?? ""}
              </Typography>
            </Box>
          </Paper>
        </ArrayLoadingSkeleton>
      </Grid>

      {results && (
        <GroupFilter list={getGroupsFromList(results.results)} onSelect={groupFilterHandler} />
      )}

      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={[test]}>
          <CompanyTestResultsStats data={test} />
        </ArrayLoadingSkeleton>
      </Grid>

      {/*<Grid item xs={12}>*/}
      {/*  <Paper>*/}
      {/*    <Box padding={2}>*/}
      {/*      <ReactApexChart options={graphOptions} series={graphSeries} type="bar" height={350} />*/}
      {/*    </Box>*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}

      <Grid item xs={12}>
        <HeaderWithIcon
          title={t("Ewaluacje uczestników")}
          count={groupedFilteredResults?.length}
          icon={DescriptionOutlinedIcon}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ margin: "1rem 0" }}>
          <Box padding={2}>
            <TextField
              label="Wyszukaj uczestnika"
              variant="outlined"
              fullWidth
              value={searchUser}
              onChange={(data) => setSearchUser(data.target.value)}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={groupedFilteredResults}>
          <CompanyTestResultsList data={groupedFilteredResults} linkTo={R_TEST_VIEW} />
        </ArrayLoadingSkeleton>
      </Grid>
    </Grid>
  );
};

export default CompanyTestStats;

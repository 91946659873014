import React from "react";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { BACKEND_URL } from "./config/config";
import App from "./containers/app/App";
import "./i18n/index";
import { createRoot } from "react-dom/client";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./themes";

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.withCredentials = true;

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          maxSnack={1}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

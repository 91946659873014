import { Box, Backdrop, CircularProgress, Toolbar } from "@mui/material";
import React, { useState } from "react";
import "../../themes/App.css";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { routes, routesAdmin, routesCompanyOwner, routesTrainer } from "../../config/routes";
import {
  getTokenUser,
  getIsAuthenticated,
  setRefreshTokenExpirationTimestamp,
  getCompany,
  setIsAuthenticated,
  setTokenUser, setCompany
} from "../../utils/tokenUtils";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Logout from "../auth/Logout";
import Login from "../auth/Login";
import axios from "axios";
import useAlerts from "../../hooks/useAlerts";
import { useTranslation } from "react-i18next";
import IUser from "../../types/IUser";

function App(): JSX.Element {
  const { t } = useTranslation();

  const { warning } = useAlerts();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  // Flaga, żeby uniknąć zapętlenia przy refreshu tokena
  let isRefreshing = false;
  let refreshSubscribers: any[] = [];

  // Funkcja do obsługi ponownego wysłania requestów po odświeżeniu tokena
  const subscribeTokenRefresh = (cb: (token: string) => void): void => {
    refreshSubscribers.push(cb);
  };

  const onRefreshed = (token: string): void => {
    refreshSubscribers.forEach((cb) => cb(token));
    refreshSubscribers = [];
  };

  // Dodajemy interceptor do Axiosa (sprawdzamy odpowiedzi)
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Jeśli dostaniemy 401 (unauthorized), próbujemy odświeżyć token
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const currentTokenUser = getTokenUser();
        const currentCompany = getCompany();

        try {
          const { data } = await axios.post(
            "/auth/refresh",
            { email: currentTokenUser?.email, company: currentCompany?.id },
            { withCredentials: true }
          );

          const newAccessToken = data.accessToken;

          setTokenUser(data.user);
          if (data.company?.id) {
            setCompany(data.company);
          }
          setIsAuthenticated(true);
          setRefreshTokenExpirationTimestamp(data.refreshTokenExpirationTimestamp);

          axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

          onRefreshed(newAccessToken);

          return axios(originalRequest);
        } catch (refreshError) {
          console.error("Błąd odświeżania tokena", refreshError);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );

  function getFinalRoutes(user: IUser | null) {
    switch (user?.role) {
      case "User":
        return routes.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Company":
        return routesCompanyOwner.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Trainer":
        return routesTrainer.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Admin":
        return routesAdmin.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
    }
  }

  if (!getIsAuthenticated() && !getTokenUser()) {
    return <Login />;
  }

  return (
    <Box display="flex" height={"100vh"}>
      <Navbar onSidebarOpen={() => setSidebarOpen(true)} />
      <Sidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
      <Box
        sx={{
          flexGrow: 1,
          padding: 2
        }}
        component={"main"}
      >
        <Toolbar />
        <Routes location={location}>
          {getFinalRoutes(getTokenUser())}
          <Route path="/logout" key={1} element={<Logout />}></Route>
          <Route path="*" key={0} element={<Navigate to="/" />}></Route>
        </Routes>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
}

export default App;
